import { useStaticQuery, graphql } from 'gatsby';

export const useMenuPageData = () => {
  const data = useStaticQuery(graphql`
    query {
      menuPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        headline
        introParagraph
        categories {
          bgColor
        }
      }
    }
  `);

  return data.menuPageDataJson;
};

import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import {
  useMenuPageData,
  Header,
  Partners,
  MenuCategory,
  containerSlideDownAnimation
} from 'sections/menuPage';
import { Spacer } from 'components';

// Data
import { useKisMenuEntries, useKisPartnersData } from 'boxenStopHooks';

const Menu = () => {
  const { seo, headline, introParagraph, categories } = useMenuPageData();

  const menus = useKisMenuEntries();
  const partners = useKisPartnersData();

  return (
    <>
      <SEO {...seo} />
      <ContentWrapper>
        <Header />
        <Partners
          headline={headline}
          partners={null}
          introParagraph={introParagraph}
        />
        <Spacer
          height={[
            '19.2rem',
            '16.1rem',
            '16.2rem',
            '12.3rem',
            '12.7rem',
            '16.9rem'
          ]}
        />
        {menus.map(
          (menu, index) =>
            menu.dishes && (
              <React.Fragment key={index}>
                <MenuCategory
                  data={categories[index]}
                  menu={menu}
                  scroller="#menu-overlay__content"
                />
                <Spacer
                  height={[
                    '15.2rem',
                    '16.4rem',
                    '15.2rem',
                    '15.6rem',
                    '25.8rem',
                    '27.9rem'
                  ]}
                />
              </React.Fragment>
            )
        )}
      </ContentWrapper>
    </>
  );
};

export default injectIntl(Menu);

const ContentWrapper = ({ children }) => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      zIndex: 1000
    }}>
    <Box
      id="menu-overlay__content"
      sx={{
        bg: 'background',
        minHeight: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
      // Animation Value
      variants={containerSlideDownAnimation}
      initial="initial"
      animate="animate"
      exit="exit">
      {children}
    </Box>
  </Box>
);
